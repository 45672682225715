@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Inter:400,500,600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Baloo+Thambi+2:600&display=swap);
html {
  background-color: rgb(255, 255, 255);
  overflow-x: hidden !important;
  font-family: "Poppins";
}

a {
  text-decoration: none;
  color: inherit;
}

.bigHomeDiv{
  margin-bottom: 20px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  overflow-x: hidden;
}

/* Bio */

.firstQRDiv {
  position: absolute;
  top: 40px;
  right: 4vw;
  display: -webkit-flex;
  display: flex;
}
.firstQRDiv > img {
  width: 21vw;
  max-width: 200px;
  height: 21vw;
  max-height: 200px;
  margin-bottom: 5px;
}
.fQrDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 23vw;
  height: auto;
  max-width: 210px;
  -webkit-align-items: center;
          align-items: center;
}
.fQrDiv > img {
  width: 9vw;
  max-width: 90px;
  height: 9vw;
  max-height: 90px;
  margin-left: 5px;
}

.bioDiv {
  padding: 10px 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px;
}
.picFlexDiv {
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
}
.picDiv {
  display: inline-block;
  height: 26vw;
  max-height: 100px;
  margin-right: 5px;
}
.picDiv > p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}
.picDiv > img {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.rightOfPicDiv {
  display: inline-block;
  /* color: #fff; */
  color: #333;
  /* border: 2px solid #000; */
}
.rightOfPicDiv > h1 {
  padding-bottom: 0vw;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}
.rightOfPicDiv > h6 {
  font-size: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: "#000000";
  font-weight: 500;
  margin: 0;
  padding-top: 40px;
}
.rightOfPicDiv > h6 > span {
  padding-right: 6px;
  padding-bottom: 10px;
}
.poplTextFlexDiv {
  display: -webkit-flex;
  display: flex;
  margin-top: 10px;
  -webkit-align-items: center;
          align-items: center;
}
.poplTextFlexDiv > p {
  margin: 0;
  padding-bottom: 0;
  font-size: 12px;
  font-weight: 700;
  padding-right: 6px;
}
.poplTextFlexDiv > a {
  height: 23px;
}
.poplTextFlexDiv > a > img {
  width: 40px;
  margin-left: -5px;
  height: 23px;
  padding: 0px 5px;
  border-radius: 2px;
  cursor: pointer;
  /* box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.185); */
  transition: 0.2s;
}
.poplTextFlexDiv > a > img:hover {
  box-shadow: 0px 0px 0px 10px #1D4B2A20;
}
.poplTeamsDiv {
  margin-bottom: 0px;
}
.poplTeamsDiv > a > img {
  width: 100px;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
  transition: 0.2s;
  border-radius: 0.3vw;
}
.poplTeamsDiv > a > img:hover {
  box-shadow: 5px 10px 10px #00000038;
}
.rightOfPicDiv > p > a {
  font-weight: 700;
  font-size: 15px;
  color: #5f0d91;
}

/* End Bio */

/* Skills */

.skillsDiv {
  width: 400px;
  border-radius: 6px;
  height: auto;
  color: #333;
  padding: 0px 20px;
}
.skillsDiv > h1 {
  font-size: 16px;
}

.skillsList {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: auto;
}
.skillsList > div {
  border-radius: 6px;
  border: 2px solid #333;
  margin: 5px;
}
.skillsList > div > p {
  font-family: "Baloo Thambi 2", cursive;
  font-size: 13px;
  padding: 5px 5px;
  margin: 0;
}

.skillsList2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 80%;
  height: auto;
}
.skillsList2 > div {
  border-radius: 6px;
  border: 2px solid #333;
  margin: 5px;
}
.skillsList2 > div > p {
  font-family: "Baloo Thambi 2", cursive;
  font-size: 13px;
  padding: 5px 5px;
  margin: 0;
}

.skillsList3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 80%;
  height: auto;
}
.skillsList3 > div {
  border-radius: 6px;
  border: 2px solid #5f0d91;
  margin: 5px;
}
.skillsList3 > div > p {
  font-family: "Baloo Thambi 2", cursive;
  font-size: 14px;
  padding: 5px 15px;
  margin: 0;
}

.showMore{
  font-family: "Poppins";
  font-size: 13px;
  color: rgb(71, 108, 255);
  font-weight: 600;
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
}

/* End Skills */
/* Projects */
.imagesDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.imagesDiv > img {
  max-width: 140px;
  height: 100%;
  max-height: 100px;
  margin-right: 5px;
}
.projectsDiv {
  width: auto;
  height: auto;
  padding: 0 30px;
  margin-left: 0.5%;
  margin-top: 10px;
  min-height: 130px !important;
}
.projectsDiv > div {
  font-size: 12px;
  font-weight: 600;
}
.projectsDiv > h5 {
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: "#000000";
  font-weight: 500;
  margin: 0;
  padding-top: 10px;
}
.projectsDiv > h5 > span {
  padding-right: 6px;
  padding-bottom: 5px;
}
.leftNav {
  margin-left: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  transition: 0.7s;
  border-radius: 0.3vw;
  width: 130px;
  padding: 5px 10px;
}
.leftNav:hover {
  /* box-shadow: 5px 10px 10px #00000038; */
}
.leftNav > img {
  height: auto;
  width: 100px;
  margin-right: 5px;
  margin-left: 5px;
}
.leftNav > svg {
  height: auto;
  width: 55px;
}
.leftNav > h1 {
  font-size: 10px;
  color: #333;
  margin: 0;
  padding-top: 2px;
  padding-left: 8px;
  font-weight: 700;
  font-family: "Poppins";
}
.leftNav > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 24px;
  margin-left: 8px;
}
.leftNav > div > p {
  font-size: 8px;
  color: #000;
  font-weight: 400;
}
.leftNav > div > img {
  font-size: 5px;
  margin-left: 4px;
  width: 30px;
  height: auto;
}
.nonflexProjectDiv {
  border-radius: 6px;
  width: 100px;
}
.projectsDiv > div > div {
  border-radius: 6px;
  display: -webkit-flex;
  display: flex;
}
.projectsDiv > div > div > div {
  margin: 10px 10px;
}
.projectsDiv > div > div  > div > h1 {
  font-family: "Poppins";
  font-size: 15px;
  color: #333;
}
.graphicsOnHomeDiv {
  display: block;
}
.xDiv {
  margin-bottom: 4%;
  transition: 0.7s;
  height: 50px;
  border-radius: 0.3vw;
  background-color: rgb(255, 255, 255);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 90px;
}
.xDiv > img {
  margin-right: 0vw;
  width: 80px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.xDiv:hover {
  box-shadow: 5px 10px 10px #00000038;
}
.xDivLarge {
  margin-bottom: 4%;
  transition: 0.7s;
  border-radius: 0.3vw;
  height: auto;
  background-color: rgb(255, 255, 255);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 180px;
}
.xDivLarge > img {
  margin-right: 0vw;
  width: 180px;
}
.xDivLarge:hover {
  box-shadow: 5px 10px 10px #00000038;
}
.portfolioDiv {
  box-shadow: 5px 8px 10px #00000068;
  width: 50%;
  margin-bottom: 0%;
  height: 100%;
  border-radius: 6px;
  transition: 0.7s;
}
.portfolioDiv:hover {
  box-shadow: 5px 10px 15px #000000ba;
}

#h1Port {
  font-family: "Poppins", sans-serif;
  position: absolute;
  font-size: 1.8vw;
  margin-top: 0.7%;
  padding-left: 2%;
  color: white;
}

/* End Projects */

/* Content */

.contentSwitcher {
  width: 110px;
  height: 36px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  border: 2px solid #09524d25;
  box-shadow: 0px 0px 2px 2px inset;
  border-radius: 100px;
  transition: 0.4s;
}
.contentOption {
  width: 50px;
  height: 34px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
}
.contentOption > svg {
  height: 19px;
  fill: #2174a8;
}

/* End Content */

.bottomRowFlex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: space-between; */
  -webkit-justify-content: center;
          justify-content: center;
}
.flagDiv {
  display: -webkit-flex;
  display: flex;
  /* flex-direction: column; */
  width: 35%;
  margin-left: 2%;
  padding: 0 20px;
  /* margin-top: 40px; */
}
.flagDiv > img {
  height: 5.5vw;
  width: 10vw;
}

/* Lines of Code */

.locDiv {
  width: 65%;
  max-width: 500px;
  text-align: center;
  height: auto;
  color: #333;
}
.locDiv > h1 {
  font-family: "Poppins";
  font-size: 9px;
  margin: 0;
  padding-bottom: 2px;
  font-weight: 600;
}
.locDiv > h1 > span {
  font-family: "Poppins";
  font-size: 11px;
  margin: 0;
  padding-left: 0px;
  font-weight: 500;
}
.locDiv > h6 {
  font-family: "Poppins";
  font-size: 11px;
  color: #000000;
  font-weight: 500;
  margin: 0;
  padding-bottom: 5px;
}
.locDiv > h6 > span {
  font-family: "Poppins";
  font-size: 11px;
  color: #000000;
  font-weight: 400;
  margin: 0 4px;
}
.locDiv > p {
  font-size: 9px;
  margin: 0;
  font-weight: 500;
  padding-bottom: 10px;
}

/* End Lines of Code */

/* Map */

.mapDiv {
  width: 100%;
  border-radius: 6px;
  margin-left: 0%;
  height: auto;
  color: #333;
}
.mapDiv > h1 {
  font-family: "Poppins";
  font-size: 1.3vw;
  width: 70%;
  margin-left: 15%;
}

/* End Map */

.footerDiv {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: baseline;
          align-items: baseline;
  font-weight: 600;
  font-size: 12px;
  width: 100vw;
  margin: 20px 0;
  padding-bottom: 50px;
  /* font-style: italic; */
}
.footerDiv > img {
  width: 300px;
  margin: 0px 4px;
  /* opacity: 0.5; */
}

/* Sign */

.signDiv {
  width: 100vw;
  margin-top: 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.azDesign {
  /* width: 60%; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.azDesign > svg > path {
  transition: 1.2s;
}
.textForMPS {
  width: 100vw;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 100px;
}
.textForMPS > p {
  width: 760px;
  font-weight: 500;
  font-size: 14px;
}
.textForMPS > p > span {
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
  margin-top: 15px;
  line-height: 3;
}

/* End Sign */

/* Sohr */

.sohr {
  width: 100vw;
  height: auto;
  background-color: #1e6287;
}

.aboutDiv {
  color: #333;
  font-family: "Poppins", sans-serif;
  padding-top: 10%;
  padding-left: 5%;
}

.picBigDiv {
  background-color: rgba(255, 14, 14, 0);
  width: 90%;
  margin-top: 10%;
  margin-left: 5%;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  color: #333;
}

/* End Sohr */


@media screen and (max-width: 800px) {
  .bioDiv {
    text-align: center;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .poplTextFlexDiv {
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .poplTextFlexDiv > p {
    padding: 0;
  }
  .mapDiv{
    margin-top: 80px;
  }
  .mainContentContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .contentSwitcher {
    margin-top: 15px;
    width: 145px;
    margin-left: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border: 2px solid #09524d25;
    box-shadow: 0px 0px 2px 2px inset;
    border-radius: 100px;
    transition: 0.4s;
  }
  .contentOption {
    width: 70px;
    height: 44px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
  }
  .contentOption > svg {
    height: 22px;
    fill: #2174a8;
  }
  .azDesign {
    width: 95%;
  }
  .textForMPS > p {
    width: 80vw;
  }
}
